import React from "react";
import { BaseLayout, OuterContainer } from "../components/Layout";
import { Gallery } from "../components/Sections";
import { graphql } from "gatsby";

const gallery = ({ data }) => {
  const arrOfBeforeAfterObjects = data.allGalleryImagesJson.edges;

  return (
    <BaseLayout page="gallery" leftBox="services" rightBox="team">
      <OuterContainer>
        <Gallery arrOfBeforeAfterObjects={arrOfBeforeAfterObjects} />
      </OuterContainer>
    </BaseLayout>
  );
};

export default gallery;

export const query = graphql`
  {
    allGalleryImagesJson {
      edges {
        node {
          after {
            childImageSharp {
              gatsbyImageData(
                quality: 95
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                height: 425
              )
            }
          }
          before {
            childImageSharp {
              gatsbyImageData(
                quality: 95
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                height: 425
              )
            }
          }
        }
      }
    }
  }
`;
